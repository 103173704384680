.StreamSetScore {
  // font-family: 'BEE FOUR/Regular';
  display: flex;

  color: white;
  background: black;

  font-size: 30px;
  display: flex;

  .ScoreContainer {
    display: flex;
    justify-content: center;
    height: 100%;
    background: #E6AC00;
  }

  .Scores {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 35px;
    font-weight: bold;
    color: black;
  }

  .Score {
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 60px;
  }

  .TeamNames {

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;

    height: 100%;
    padding: 5px;
    padding-right: 15px;
    background: #870000;
    background: black;
  }
  .TeamName {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;

    .serving {
      height: 40px;
      width: 40px;
      svg {
        fill: white;
      }
    }
  }

}